<template>

<div>
    <!-- Preloader - style you can find in spinners.css -->
    <div class="preloader" style="display: none;">
        <div class="lds-ripple">
            <div class="lds-pos"></div>
            <div class="lds-pos"></div>
        </div>
    </div>    

  
    <section class="container-fluid p-0" style="background-color: #fff;">
            <div class="col-12">
                <div class="row">
                     <div class="col-12 col-md-12 col-lg-6 d-flex bg-login" :style='isMobile ? this.mobile_background : this.desktop_background'>
                           
                        <div class="align-self-end p-bg-login">
                            <h1 class="text-white font-b-5">{{this.sentence}}
                            </h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-6 align-self-center">
                     
                    <div>
                        <div class="col-12 text-center p-entrar-login">                            
                            <b-link to="/">
                                <img :src="this.logo" alt="Logo" style="max-height: 66px;">
                            </b-link>     

                            <h2 class="pt-4 font-b-7" style="font-size: 18px; color:#7f7f7f">Acesse sua conta</h2>
                                           
                        <div class="d-flex mx-auto" style="max-width:max-content">
                            <div class="mr-3">
                                 <b>Login com email</b>

                                <!-- <a href="javacript:void(0)" v-bind:class = "showEmailLogin?'activetype':''" @click="changeLogin('email')"><b>Login com email</b></a> -->
                            </div>
                             <!-- - 
                            <div class="ml-3">
                                <a href="javacript:void(0)"  v-bind:class = "showSmsLogin?'activetype':''" @click="changeLogin('sms')"><b>Login por SMS</b></a>
                            </div> -->
                        </div>                           
                    </div>

                    <div class="col-12 col-md-10 col-lg-9 mx-auto pb-4 loginemail"  v-if="showEmailLogin == true">
                            <p class="pt-1 mt-2 text-center" style="font-size: 12px; color:#7f7f7f">
                                Digite seu email e senha para entrar.<br>
                            </p>
                            <!--<form action="" class="needs-validation" novalidate="">-->
                            <div class="col-12">
                                <form @submit.prevent="login" novalidate>
                                <div class="col-12">
                                    <label class="d-none">E-mail </label>
                                    <input type="email" v-model="email" class="form-control inputs-login" placeholder="E-mail" name="email" v-validate="'required|email'">
                                    <b-form-invalid-feedback :state="!errors.has('email')">
                                        Por favor! Informe o seu e-mail.
                                    </b-form-invalid-feedback>
                                </div>
                                <div class="col-12 pt-2">
                                    <label class="d-none">Senha </label>
                                    <input type="password" class="form-control inputs-login" id="passtext" placeholder="********" v-model="password" name="password" v-validate="'required'">
                                    <b-form-invalid-feedback :state="!errors.has('password')">
                                        Por favor! Informe a senha.
                                    </b-form-invalid-feedback>
                                </div>
                                
                                <b-form-invalid-feedback :state="!error">
                                   {{error_mensagem}}
                                    </b-form-invalid-feedback>
                                <div class="col-12 text-right pt-2">
                                    <b-link :to="{name: 'AgentRecovery'}" title="Senha" class="font-12 a-link-1">Esqueci minha senha?</b-link>
                                </div>
                                <div class="d-flex">
                                        <div class="col-12 text-center pt-3">
                                        <div class="col-11 mx-auto text-center font-12 position-relative">
                                        <input type="checkbox" v-model="check" class="position-absolute" style="width: 19px; height:19px;left:-5px;top:0px;" />
                                        Ao continuar, você concorda com os <a  :href="'https://'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);"><b>Termos de Uso</b></a> e está ciente da <a :href="'https://'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);"> <b>Política
                                        de Privacidade</b></a>.
                                    </div>   
                                <button :disabled="check==false" style="background-color: var(--color-broker)" type="button" @click="login()" class="btn mt-2 btn-info button-login">Entrar</button>
                               </div>   
                                </div>
                                </form>
                            </div>                                                
                        </div>


                       
                       
                        <div v-if="showSmsLogin == true" class="col-12 col-md-10 col-lg-9 mx-auto pb-4 loginsms">
                            <p class="pt-1 mt-2 text-center" style="font-size: 12px; color:#7f7f7f">
                                Digite seu celular para entrar. Seus dados<br>
                                estão seguros e você não precisa de senha.
                            </p>
                            <!--<form action="" class="needs-validation" novalidate="">-->
                            <div class="col-12">
                                <div class="d-flex mx-auto" style="max-width:max-content">
                                    <div class="align-self-center mx-auto">
                                        <div class="d-flex">
                                        
                                            <AreaCode :defaultCountry="cellphone.cellphone_area_code_iso" @setAreaCode="setPhoneAreaCode"/>
                                        </div>
                                    </div>
                                    <div class="align-self-center px-3 mx-auto">
                                        <label class="d-none">DDD</label>
                                        <input type="tel" v-mask="['##']" v-model="cellphone.ddd" placeholder="DDD" class="form-control inputs-login" value="DDD" style="max-width: 70px">
                                        <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu e-mail.</div>
                                    </div>
                                    <div class="align-self-center mx-auto">
                                        <label class="d-none">Celular </label>
                                        <input type="tel" v-model="cellphone.cellphone" class="form-control inputs-login" id="cellphone" placeholder="Celular" v-mask="['# ####-####']" style="max-width: 300px">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center pt-2">
                                <p class="pt-1" style="font-size: 12px; color:#7f7f7f">
                                    Você vai receber um código para confirmar seu telefone.
                                </p>
                            </div>
                            <div class="col-12 text-center">
                                <button style="background-color: var(--color-broker)" type="button" @click="getCode('CELLPHONE')" class="btn btn-info button-login">Receber código por SMS</button>
                            </div>                            
                        </div>


                            <!-- <div class="col-12 text-center pt-3">
                                <a href="http://quesuite.com.br/views/portaldocorretor/?app=dashboard.php">
                                    <button style="background-color: var(--color-broker)" type="submit" class="btn btn-info button-login">Receber código por Whatsapp</button>
                                </a>
                            </div> -->
                            <div class="col-12 text-center pt-3">
                                <b-link to="/novo-cadastro" title="Senha" class="font-13 a-link-1">Criar sua conta</b-link>
                            </div>
                            
                            <!--</form>-->
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    <footer class="footer text-center text-muted p-0">
        <div class="col-12 py-3">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-9 col-lg-10 text-center text-sm-left align-self-center mx-auto py-2">
                        © {{new Date(Date.now()).getFullYear()}} | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a :href="'https://portaldocorretor.'+site+'/termos-de-uso'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de uso</a> | <a :href="'https://portaldocorretor.'+site+'/politica-de-privacidade'" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Política de privacidade</a> | <a href="https://r4marketing.com.br/termos-de-uso" class="a-link-1" style="text-decoration:none; color:var(--color-broker);">Termos de Uso</a>
                    </div>
                    <div class="col-12 col-md-3 col-lg-2 text-center text-sm-right align-self-center">
                        <a href="https://qsuite.com.br" target="_blank"><img src="@/assets/img/logo-gsuite.png" alt="Qsuite" style="max-height: 23px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
    
    

</template>
<script>

import ThemeService from "@/services/resources/ThemeService";
import LoginCodeService from "@/services/resources/LoginCodeService";
import AreaCode  from "@/components/AreaCode.vue";
const serviceTheme = ThemeService.build();
const serviceCode = LoginCodeService.build();

export default {
    components: {
            AreaCode
        },
        watch: {
            "cellphone.ddd": (value) => {
                if(value.length === 2){
                    document.getElementById('cellphone').focus();
                }
            }
        },
    data() {
        return {
            width: null,
            email: null,
            password: null,
            check: false,
            site: "",
            sentence: '',
            component_color: null,
            background_color: '#fff',
            active_register: null,
            desktop_background: null,
            mobile_background: null,
            logo: null,
            error: false,
            error_mensagem: "",
            company_id: null,
            forget_password: window.location.pathname === '/sindico' ? 'LiquidatorRecovery' : 'ClientRecovery',
            cellphone: {
                cellphone_area_code_iso: 'BR',
                cellphone_area_code: '55',
                cellphone: '',
                ddd: ''
            },
            showEmailLogin: true,
            showSmsLogin: false
        };
    },
    methods:{
        getCode(type) {
            let data = {
                company_id: this.company_id,
                type,
                cellphone_area_code: this.cellphone.cellphone_area_code,
                cellphone: '(' + this.cellphone.ddd + ')' + ' ' + this.cellphone.cellphone
            };

            serviceCode.search(data).then(response => {
                this.$router.push(`/login-corretor?phone=${this.cellphone.cellphone_area_code} ${this.cellphone.ddd  + this.cellphone.cellphone}`);
            }).catch(err => {
                this.$bvToast.toast('Número de telefone inválido', {
                        title: 'Envio de código',
                        autoHideDelay: 5000,
                        type: 'danger   '
                    });

                    this.cellphone.cellphone = '',
                    this.cellphone.ddd = ''

            });
        },
        setPhoneAreaCode(value) {
           
           this.$set(this.cellphone, 'cellphone_area_code', value);
       },
        required(image){
            if(!image || image.length == 0){
                return null;
            }
            if(image.includes('https')){
                return image;
            }

            return require(`@/assets/img/${image}`); 
        },
        login(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                let url = window.location.pathname;
                let role_id = 6;//Corretor               

               let data = {
                    username: this.email,
                    password: this.password,
                    role_id
                };

                this.$store
                .dispatch("loginRequest", data)
                .then(() => {
                })
                .catch((err) => {
                    this.error = true;
                    this.error_mensagem = err
                });
                }
            });
        },
        changeLogin(module) {
            if (module == "email") {
                this.showEmailLogin = true
                this.showSmsLogin = false
            } else {
                this.showSmsLogin = true
                this.showEmailLogin = false
            }
        },
        fetchTheme(){
            let data = {
                domain: window.location.hostname
            };

            serviceTheme
            .search(data)
            .then(resp => {
                resp.forEach(element => {
                    this.company_id = element.company_id;
                    switch(element.key){
                        case 'PORTAL_BROKER_SENTENCE':
                            this.sentence = element.value;
                            break;
                        case 'PORTAL_CLIENT_COMPONENT_COLOR':
                            this.component_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_BACKGROUND_COLOR':
                            this.background_color = element.value;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.active_register = element.value;
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_DESKTOP':
                            this.desktop_background = `background-image: url("${element.value}")`;
                            break;
                        case 'PORTAL_BROKER_BACKGROUND_MOBILE':
                            this.mobile_background = `background-image: url("${element.value}")`;;
                            break;
                        case 'PORTAL_CLIENT_ACTIVE_REGISTER':
                            this.active_register = element.value;
                            break;
                      
                        case 'SETTINGS_LOGO':
                            this.logo = element.value;
                            break;
                    }
                });
            });
        },
        
        handleResize() {
      // padrão
            this.width = window.innerWidth;
         },
    },
    computed: {
        isMobile() {
            return this.width <= 768;
        },
    },
    mounted(){
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
         this.site=window.location.host
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.fetchTheme();
    
    }
}
</script>

<style>

    .activetype {
        border-bottom: 2px solid gray;
    }
</style>